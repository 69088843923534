<template>
  <Validator
    ref="validator"
    :value="modelValue"
    :rules="rules"
    :hint="hint"
    class="textfield__validator"
    :message-id="messageId"
    @valid="onValid"
  >
    <div
      class="textfield__wrapper"
      :data-cy="dataCy"
      @mousedown="onMousedown($event)"
      @keypress.enter="handleEnter"
    >
      <span v-if="title" class="subsubtitle textfield__title">{{ title }}</span>
      <div
        :class="{
          textfield__disabled: disabled,
          textfield__focused: focused,
          textfield__invalidated: invalidated,
          textfield__clicked: mouseUsed
        }"
        class="input__slot"
      >
        <label
          v-if="label"
          :style="{ 'margin-right': showIcon ? `${iconSpace}px` : undefined }"
          :class="{ magnify: magnified }"
          :for="textfieldId"
          class="textfield__label"
        >
          {{ label }}
        </label>
        <input
          :id="textfieldId"
          ref="textfield"
          class="input__element"
          :aria-describedby="!valid || hint ? messageId : undefined"
          :aria-invalid="!valid ? true : undefined"
          :style="{ 'margin-right': showIcon ? `${iconSpace}px` : undefined }"
          :type="type"
          :value="modelValue"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :tabindex="tabindex"
          :max="max"
          :min="min"
          :maxlength="maxlength"
          :autocomplete="autocomplete"
          :name="name"
          :autofocus="autofocus"
          @input="onInput"
          @blur="onBlur($event)"
          @focus="onFocus($event)"
          @mousedown="onMousedown($event)"
          @keypress.enter.prevent="$emit('keypress', $event)"
        />
        <div v-if="showIcon" class="textfield__icon">
          <Icon
            v-if="showCheckmark"
            class="icon"
            name="checkmark"
            height="24"
            color="#17b95c"
          />
          <slot name="append" />
        </div>
      </div>
    </div>
  </Validator>
</template>

<script>
import { nextTick } from 'vue'
import { uniqueId } from 'src/lib/helpers'
import Icon from '../icon/icon.vue'
import Validator from './validator.vue'

export default {
  name: 'TextField',
  components: {
    Validator,
    Icon
  },
  props: {
    modelValue: { type: String, default: undefined },
    label: { type: String, default: undefined },
    dataCy: { type: String, default: undefined },
    disableCheckmark: { type: Boolean, default: false },
    invalidated: { type: Boolean, default: false },
    title: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    hint: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    tabindex: { type: Number, default: undefined },
    readonly: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: undefined },
    maxlength: { type: [String, Number], default: undefined },
    max: { type: [String, Number], default: undefined },
    min: { type: [String, Number], default: undefined },
    autocomplete: { type: String, default: undefined },
    name: { type: String, default: undefined },
    filters: { type: Array, default: () => [] },
    onEnter: {
      type: Function,
      default: undefined
    },
    autofocus: { type: Boolean, default: false }
  },
  emits: ['valid', 'dirty', 'focus', 'blur', 'keypress', 'update:modelValue'],
  data() {
    return {
      valid: undefined,
      initialValid: false,
      focused: false,
      messageId: `mess-${uniqueId()}`,
      mouseUsed: false,
      isDirty: false
    }
  },
  computed: {
    textfieldId() {
      let prefix = ''

      if (this.name && this.name.length > 1) {
        prefix = this.name
      } else if (
        this.label &&
        this.label.length > 1 &&
        this.label.match(/\w+/)
      ) {
        prefix = this.label.match(/\w+/)[0]
      }

      return `${prefix}-${uniqueId()}`
    },
    magnified() {
      return !this.modelValue && !this.focused && !this.placeholder
    },
    showCheckmark() {
      if (this.disableCheckmark || this.disabled || this.invalidated) {
        return false
      }

      if (this.modelValue && this.modelValue.length > 0) {
        if (this.valid === undefined) {
          return this.initialValid
        } else {
          return this.valid
        }
      }

      return false
    },
    showIcon() {
      return this.showCheckmark || this.$slots.append
    },
    iconSpace() {
      return this.showCheckmark && this.$slots.append ? '48' : '24'
    }
  },
  mounted() {
    if (this.modelValue && this.modelValue.length > 0) {
      this.initialValid = this.validate(false)
    } else {
      this.initialValid = false
    }
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', undefined)
      this.$refs.validator.reset()
    },
    validate(showError = false, focus = false) {
      const isValid = this.$refs.validator?.validate(showError)

      if (!isValid && focus) {
        this.focus()
      }

      return isValid
    },
    focus() {
      this.$refs.textfield.focus()
    },
    onFocus(event) {
      if (!this.focused) {
        this.focused = true
        if (this.mouseUsed) {
          this.$refs.validator.reset()
        }

        this.$emit('focus', event)
      }
    },
    onBlur(event) {
      if (this.type.toLowerCase() !== 'password') {
        nextTick(() =>
          this.$emit('update:modelValue', this.applyFilters(this.modelValue))
        )
      }

      this.mouseUsed = false
      this.focused = false
      this.validate(true)
      this.$emit('blur', event)
    },
    onInput(event) {
      if (event && event.target) {
        let value = event.target.value

        if (typeof value === 'string') {
          value = value.trim()
        }

        this.$emit('update:modelValue', value)

        if (!this.isDirty) {
          this.$emit('dirty')
          this.isDirty = true
        }
      }
    },
    applyFilters(value) {
      if (this.filters.length === 0) {
        return value
      }

      this.filters.forEach(filter => {
        if (typeof filter === 'function') {
          value = filter(value)
        }
      })

      return value
    },
    onValid(valid) {
      this.valid = valid
      this.$emit('valid', valid)
    },
    onMousedown(event) {
      this.mouseUsed = true
      if (event.target !== this.$refs.textfield) {
        event.preventDefault()
      }

      this.focus()
    },
    handleEnter(event, stopPropagation) {
      if (this.onEnter) {
        if (event && stopPropagation) {
          event.stopPropagation()
        }

        this.onEnter()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.textfield__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.textfield__label {
  color: rgb(var(--root-color-rgb) / 70%);
  line-height: 1.1;
  height: calc-rem(17px);
  max-width: calc(100vw - 120px);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc-rem(13px);
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}

.input__element {
  line-height: 1.25;
  border: 0;
  outline: none;
  padding-bottom: 8px;
  padding-top: 2px;

  &:focus {
    box-shadow: none;
  }
}

.input__slot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1.5px solid $input-border-color;
  min-height: 56px;
  padding-right: 12px;
  padding-top: 6px;
  padding-left: 15px;
  background-color: var(--background-primary);
  cursor: text;
  border-radius: 5px;

  &.textfield__focused {
    border: 1.5px solid $input--active-border-color;
    caret-color: $input--active-border-color;
    box-shadow: 0 0 0 2px var(--background-primary), 0 0 0 4px var(--root-color);
    transition: box-shadow 0.2s;
    transform-origin: center;

    > .textfield__label {
      color: $input--active-border-color;
    }
  }

  &:hover {
    border: 1.5px solid $input--active-border-color;
  }

  &.textfield__clicked {
    box-shadow: none;
  }
}

@include is-dark {
  .input__slot {
    color: var(--root-color);
  }
}

.show-validation-error .input__slot,
.textfield__invalidated.input__slot {
  border: 1.5px solid var(--error-color);

  > .textfield__label {
    color: var(--error-color);
  }

  &:hover {
    border: 1.5px solid var(--error-color);
  }
}

.input__slot.textfield__disabled {
  border: 0;
  cursor: default;
  background-color: var(--background-tertiary);
  color: rgb(var(--root-color-rgb) / 45%);

  > .textfield__label {
    color: rgb(var(--root-color-rgb) / 45%);
  }

  > .input__element {
    cursor: default;
  }

  &:hover {
    border: 0;
  }
}

.magnify {
  height: 1.12rem;
  transform-origin: 0 0;
  font-size: calc-rem(16px);
  transform: translateY(12px);
}

.textfield__icon {
  position: absolute;
  top: calc(100% / 2 - 12px);
  right: 8px;
}
</style>
